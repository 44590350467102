.tmassignee {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.25em 0.25em 0 0.25em;
  background-color: white;
  color: black;
  border: 2px solid white;
  border-radius: 1em;
}

.tmassignee[data-selected="true"] {
  opacity: 0.8;
}

.tmassignee:hover,
.tmassignee:focus {
  border: 2px solid darkgray;
  cursor: pointer;
}

.tmassignee > img {
  width: 4em;
  border-radius: 1em;
}

.tmassignee > span {
  font-size: larger;
  font-weight: bold;
  padding-left: 0.25em;
}
