textarea {
  font-family: "Abel", sans-serif;
}

.auth {
  font-family: "Abel", sans-serif;
  font-weight: bold;
  border: 2px solid var(--tm-fg-color);
  background-image: var(--tm-bg-gradient);
  color: var(--tm-fg-color);
  color: black;
  border-radius: 0.8em;
  -webkit-animation-name: fadein;
  animation-name: fadein;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.auth-header {
  background-color: darkblue;
  color: white;
  width: 100%;
  border-radius: 0.7em 0.7em 0 0;
  margin: 0;
  padding: 0.5em;
}

.auth-detail {
  width: 100%;
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.auth-error {
  background-color: red;
  color: white;
  font-weight: bold;
  border: double 2px white;
  padding: 0.25em;
  width: 100%;
  text-align: center;
}

.auth-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0.5em 0.5em 0.5em;
}

.auth-info {
  margin-bottom: 0.25em;
  font-weight: initial;
  color: yellow;
}

.auth-buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.auth-extra-buttons {
  margin-top: 0.5em;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: minmax(25px, auto);
  align-items: center;
  -moz-column-gap: 0.5em;
  column-gap: 0.5em;
}

.avatar-list {
  display: grid;
  grid-template-columns: repeat(6, 5em);
  grid-auto-rows: 5em;
  padding-bottom: 0.25em;
}

.avatar-item {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  border: solid 2px transparent;
}

.avatar-item:hover,
.avatar-item:focus {
  border: 2px solid yellow;
}

.avatar-item[data-selected="true"] {
  border: 2px solid white;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
