.mbcard {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100%;
  border: 2px solid white;
}

.mbcard:hover {
  border: 2px solid yellow;
}

.mbcard[data-disabled="true"]:hover {
  pointer-events: none;
}

.mbcard[data-value="Blank"] {
  background-image: url("./images/Blank.png");
}
.mbcard[data-value="25"] {
  background-image: url("./images/25.png");
}
.mbcard[data-value="50"] {
  background-image: url("./images/50.png");
}
.mbcard[data-value="75"] {
  background-image: url("./images/75.png");
}
.mbcard[data-value="100"] {
  background-image: url("./images/100.png");
}
.mbcard[data-value="200"] {
  background-image: url("./images/200.png");
}
.mbcard[data-value="Hazard-Accident"] {
  background-image: url("./images/Hazard-Accident.png");
}
.mbcard[data-value="Hazard-Flat Tire"] {
  background-image: url("./images/Hazard-Flat Tire.png");
}
.mbcard[data-value="Hazard-Out of Gas"] {
  background-image: url("./images/Hazard-Out of Gas.png");
}
.mbcard[data-value="Hazard-Speed Limit"] {
  background-image: url("./images/Hazard-Speed Limit.png");
}
.mbcard[data-value="Hazard-Stop"] {
  background-image: url("./images/Hazard-Stop.png");
}
.mbcard[data-value="Remedy-End of Speed Limit"] {
  background-image: url("./images/Remedy-End of Speed Limit.png");
}
.mbcard[data-value="Remedy-Gasoline"] {
  background-image: url("./images/Remedy-Gasoline.png");
}
.mbcard[data-value="Remedy-Go"] {
  background-image: url("./images/Remedy-Go.png");
}
.mbcard[data-value="Remedy-Repairs"] {
  background-image: url("./images/Remedy-Repairs.png");
}
.mbcard[data-value="Remedy-Spare Tire"] {
  background-image: url("./images/Remedy-Spare Tire.png");
}
.mbcard[data-value="Safety-Driving Ace"] {
  background-image: url("./images/Safety-Driving Ace.png");
}
.mbcard[data-value="Safety-Extra Tank"] {
  background-image: url("./images/Safety-Extra Tank.png");
}
.mbcard[data-value="Safety-Punture Proof"] {
  background-image: url("./images/Safety-Punture Proof.png");
}
.mbcard[data-value="Safety-Right of Way"] {
  background-image: url("./images/Safety-Right of Way.png");
}
