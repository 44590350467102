.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.home::-webkit-scrollbar {
  margin-block: 0.5em;
}

.home::-webkit-scrollbar-track {
  background-color: black;
  border: 2px solid var(--primary-color);
  border-radius: 100vw;
}

.home::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 100vw;
}

@supports (scrollbar-color: red blue) {
  .home {
    scrollbar-color: var(--primary-color) black;
  }
}

.home-section {
  padding: 1rem;
  width: 100ch;
  max-width: 90%;
  word-wrap: break-word;
}

.home-section:nth-last-of-type(1) {
  padding-bottom: 5em;
}

.home-section-border {
  border: 2px solid var(--primary-color);
  margin-top: 0;
  padding: 0.5em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.home-section h2 {
  text-transform: uppercase;
  background-color: var(--primary-color);
  color: black;
  margin-bottom: 0;
  padding: 0.3em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.home-section p {
  margin: 0.25rem;
  font-size: 1.5em;
}

.home-section a {
  text-decoration: none;
  color: lime;
}

.home-hovermenu {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary-color);
  font-weight: bold;
  opacity: 90%;
  border-radius: 0.5em;
}

.home-hovermenu ul {
  list-style: none;
  margin: 0;
  padding: 0.25em;
  display: flex;
}

.home-hovermenu li {
  padding: 0.25em;
}

.home-hovermenu a {
  padding: 0.25em;
  text-decoration: none;
  color: black;
  border-radius: 0.5em;
  transition: all 0.5s;
}

.home-hovermenu a:hover {
  background-color: black;
  color: var(--primary-color);
}

.resume-company {
  font-size: 1.5em;
  text-transform: uppercase;
}

.resume-title {
  font-size: 1.25em;
}

.resume-dates {
  font-style: italic;
}

.resume-list {
  margin-left: 2em;
}

.education-school {
  font-size: 1.5em;
  text-transform: uppercase;
}

.education-detail {
  font-size: 1.25em;
}

.home-skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
}

.home-certifications {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  color: black;
}

.home-certifications a {
  color: blue;
  -webkit-text-decoration: underline;
  text-decoration:underline;
}

.home-certifications a:hover {
  color: purple;
}
