.hangman {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  -webkit-animation: hangman-enter 1s;
  animation: hangman-enter 1s;
}

@-webkit-keyframes hangman-enter {
  from {
    transform: translateY(-100vh);
  }
}

@keyframes hangman-enter {
  from {
    transform: translateY(-100vh);
  }
}

.hangman > h2 {
  margin: 0.5rem;
}

.hangman-information {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}
