.error-modal,
.info-modal,
.spinner-modal {
  font-family: "Abel", sans-serif;
  position: fixed;
  max-width: 50ch;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.25em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-modal {
  border: 2px double white;
  background-color: red;
  color: white;
}

.info-modal {
  text-align: center;
  border: 2px solid blue;
  background-color: white;
  color: blue;
  font-weight: bold;
}

.spinner-modal {
  border: none;
}

.transparent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}
