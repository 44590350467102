.memory-tiles {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 79px);
  grid-template-rows: repeat(4, 103px);
  grid-gap: 0.25em;
}

.memory-tile {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100%;
  border: 2px solid white;
  transition: all linear 500ms;
}

.memory-tile[data-disabled="true"]:hover {
  pointer-events: none;
}

.memory-tile[data-value="-1"] {
  background-image: url("./images/blank.png");
}
.memory-tile[data-value="0"] {
  background-image: url("./images/back.png");
}
.memory-tile[data-value="1"] {
  background-image: url("./images/01.png");
}
.memory-tile[data-value="2"] {
  background-image: url("./images/02.png");
}
.memory-tile[data-value="3"] {
  background-image: url("./images/03.png");
}
.memory-tile[data-value="4"] {
  background-image: url("./images/04.png");
}
.memory-tile[data-value="5"] {
  background-image: url("./images/05.png");
}
.memory-tile[data-value="6"] {
  background-image: url("./images/06.png");
}
.memory-tile[data-value="7"] {
  background-image: url("./images/07.png");
}
.memory-tile[data-value="8"] {
  background-image: url("./images/08.png");
}

@media screen and (max-width: 400px) {
  .memory-tiles {
    grid-template-columns: repeat(4, 70px);
    grid-template-rows: repeat(4, 91px);
  }
}
