.videopoker-card {
  width: 100%;
  height: 100%;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100%;
}

.videopoker-card[data-index="0"] {
  background-image: url("./images/ace_of_spades.png");
}
.videopoker-card[data-index="1"] {
  background-image: url("./images/2_of_spades.png");
}
.videopoker-card[data-index="2"] {
  background-image: url("./images/3_of_spades.png");
}
.videopoker-card[data-index="3"] {
  background-image: url("./images/4_of_spades.png");
}
.videopoker-card[data-index="4"] {
  background-image: url("./images/5_of_spades.png");
}
.videopoker-card[data-index="5"] {
  background-image: url("./images/6_of_spades.png");
}
.videopoker-card[data-index="6"] {
  background-image: url("./images/7_of_spades.png");
}
.videopoker-card[data-index="7"] {
  background-image: url("./images/8_of_spades.png");
}
.videopoker-card[data-index="8"] {
  background-image: url("./images/9_of_spades.png");
}
.videopoker-card[data-index="9"] {
  background-image: url("./images/10_of_spades.png");
}
.videopoker-card[data-index="10"] {
  background-image: url("./images/jack_of_spades.png");
}
.videopoker-card[data-index="11"] {
  background-image: url("./images/queen_of_spades.png");
}
.videopoker-card[data-index="12"] {
  background-image: url("./images/king_of_spades.png");
}
.videopoker-card[data-index="13"] {
  background-image: url("./images/ace_of_hearts.png");
}
.videopoker-card[data-index="14"] {
  background-image: url("./images/2_of_hearts.png");
}
.videopoker-card[data-index="15"] {
  background-image: url("./images/3_of_hearts.png");
}
.videopoker-card[data-index="16"] {
  background-image: url("./images/4_of_hearts.png");
}
.videopoker-card[data-index="17"] {
  background-image: url("./images/5_of_hearts.png");
}
.videopoker-card[data-index="18"] {
  background-image: url("./images/6_of_hearts.png");
}
.videopoker-card[data-index="19"] {
  background-image: url("./images/7_of_hearts.png");
}
.videopoker-card[data-index="20"] {
  background-image: url("./images/8_of_hearts.png");
}
.videopoker-card[data-index="21"] {
  background-image: url("./images/9_of_hearts.png");
}
.videopoker-card[data-index="22"] {
  background-image: url("./images/10_of_hearts.png");
}
.videopoker-card[data-index="23"] {
  background-image: url("./images/jack_of_hearts.png");
}
.videopoker-card[data-index="24"] {
  background-image: url("./images/queen_of_hearts.png");
}
.videopoker-card[data-index="25"] {
  background-image: url("./images/king_of_hearts.png");
}
.videopoker-card[data-index="26"] {
  background-image: url("./images/ace_of_clubs.png");
}
.videopoker-card[data-index="27"] {
  background-image: url("./images/2_of_clubs.png");
}
.videopoker-card[data-index="28"] {
  background-image: url("./images/3_of_clubs.png");
}
.videopoker-card[data-index="29"] {
  background-image: url("./images/4_of_clubs.png");
}
.videopoker-card[data-index="30"] {
  background-image: url("./images/5_of_clubs.png");
}
.videopoker-card[data-index="31"] {
  background-image: url("./images/6_of_clubs.png");
}
.videopoker-card[data-index="32"] {
  background-image: url("./images/7_of_clubs.png");
}
.videopoker-card[data-index="33"] {
  background-image: url("./images/8_of_clubs.png");
}
.videopoker-card[data-index="34"] {
  background-image: url("./images/9_of_clubs.png");
}
.videopoker-card[data-index="35"] {
  background-image: url("./images/10_of_clubs.png");
}
.videopoker-card[data-index="36"] {
  background-image: url("./images/jack_of_clubs.png");
}
.videopoker-card[data-index="37"] {
  background-image: url("./images/queen_of_clubs.png");
}
.videopoker-card[data-index="38"] {
  background-image: url("./images/king_of_clubs.png");
}
.videopoker-card[data-index="39"] {
  background-image: url("./images/ace_of_diamonds.png");
}
.videopoker-card[data-index="40"] {
  background-image: url("./images/2_of_diamonds.png");
}
.videopoker-card[data-index="41"] {
  background-image: url("./images/3_of_diamonds.png");
}
.videopoker-card[data-index="42"] {
  background-image: url("./images/4_of_diamonds.png");
}
.videopoker-card[data-index="43"] {
  background-image: url("./images/5_of_diamonds.png");
}
.videopoker-card[data-index="44"] {
  background-image: url("./images/6_of_diamonds.png");
}
.videopoker-card[data-index="45"] {
  background-image: url("./images/7_of_diamonds.png");
}
.videopoker-card[data-index="46"] {
  background-image: url("./images/8_of_diamonds.png");
}
.videopoker-card[data-index="47"] {
  background-image: url("./images/9_of_diamonds.png");
}
.videopoker-card[data-index="48"] {
  background-image: url("./images/10_of_diamonds.png");
}
.videopoker-card[data-index="49"] {
  background-image: url("./images/jack_of_diamonds.png");
}
.videopoker-card[data-index="50"] {
  background-image: url("./images/queen_of_diamonds.png");
}
.videopoker-card[data-index="51"] {
  background-image: url("./images/king_of_diamonds.png");
}
.videopoker-card[data-hidden="true"] {
  background-image: url("./images/cardback_red.png");
}
