.tmtasknote {
  display: flex;
  flex-direction: column;
  margin: 0.25em 0.25em 0 0.25em;
  background-color: white;
  color: black;
  border-radius: 1em;
  padding-bottom: 0.25em;
}

.tmtasknote > h3 {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

.tmtasknote > p {
  margin: 0 0.25em 0.25em 0.25em;
}

.tmtasknote > button {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin-left: 50%;
  transform: translateX(-50%);
}
