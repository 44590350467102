.videopoker {
  border: 1px solid white;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: blue;
  -webkit-animation: videopoker-enter 1s;
  animation: videopoker-enter 1s;
}

@-webkit-keyframes videopoker-enter {
  from {
    transform: translateY(100vh);
  }
}

@keyframes videopoker-enter {
  from {
    transform: translateY(100vh);
  }
}

.videopoker > h2 {
  margin: 0.5rem;
}
