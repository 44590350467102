.millebornes {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-animation: millebornes-enter 1s;
  animation: millebornes-enter 1s;
}

.millebornes > h2 {
  margin: 0.5rem;
}

.millebornes-cards {
  display: grid;
  grid-template-columns: repeat(5, 63px);
  grid-auto-rows: 87px;
  gap: 1em;
}

@-webkit-keyframes millebornes-enter {
  from {
    transform: rotate(180deg);
  }
}

@keyframes millebornes-enter {
  from {
    transform: rotate(180deg);
  }
}
