.hangman-graphic {
  margin-top: 0.5rem;
  background-image: url("./images/hangman.gif");
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: repeat(1, 9em);
  grid-template-rows: repeat(10, 0.9em);
  transition: 3s ease-in-out;
}

.hangman-graphic[data-won="true"] {
  background-image: url("./images/hangmanWon.gif");
}

.hangman-graphic[data-lost="true"] {
  background-image: url("./images/hangmanLost.gif");
}

.hangman-graphic-tile {
  background: transparent;
  transition: 500ms ease-in-out;
}

.hangman-graphic-tile[data-hidden="true"] {
  background: white;
}

@media screen and (max-width: 400px) {
  .hangman-graphic {
    grid-template-columns: repeat(1, 15rem);
    grid-template-rows: repeat(10, 1.5rem);
  }
}
