.tmtasknotes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  border-right: solid black 2px;
  border-bottom: solid black 2px;
  border-left: solid black 2px;
}

.tmtasknotes-header {
  width: 100%;
  padding-bottom: 0.25em;
  border-bottom: solid black 2px;
}

.tmtasknotes-title {
  width: 100%;
  text-align: center;
}

.tmtasknotes-title > h3 {
  margin: 0.2em;
}

.tmtasknotes-buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.tmtasknotes-items {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 1em;
}
