.task-manager {
  font-family: "Abel", sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: var(--tm-bg-gradient);
  color: var(--tm-fg-color);
}

.task-manager-menu-bar {
  display: flex;
  width: 100%;
}

.task-manager-group {
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.auth-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.auth-modal {
  position: fixed;
  max-width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
